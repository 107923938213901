import { twMergeClasses } from '../../lib';
import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    orientation: 'segmented';
}

export const ButtonGroup = forwardRef<HTMLDivElement, Props>(
    ({ children, orientation, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses('box-border inline-flex gap-2', {
                    'first:rounded-l last:rounded-r': orientation === 'segmented',
                })}
                {...props}
            >
                {children}
            </div>
        );
    },
);

ButtonGroup.displayName = 'Button';
