'use client';

import { twMergeClasses } from '../../lib';
import { CloseIcon } from '@in2event/icons';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const portalPositionVariants = {
    left: 'justify-start left-0',
    right: 'justify-end right-0',
};

type PositionVariants = keyof typeof portalPositionVariants;

interface SheetPortalProps extends SheetPrimitive.DialogPortalProps {
    position?: PositionVariants;
}

const SheetPortal = ({ position = 'right', children, ...props }: SheetPortalProps) => (
    <SheetPrimitive.Portal {...props}>
        <div
            className={twMergeClasses(
                'fixed inset-y-0 z-50 flex w-full md:max-w-[440px]',
                portalPositionVariants[position],
            )}
        >
            {children}
        </div>
    </SheetPrimitive.Portal>
);
SheetPortal.displayName = SheetPrimitive.Portal.displayName;

const SheetOverlay = forwardRef<
    ElementRef<typeof SheetPrimitive.Overlay>,
    ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Overlay
        className={twMergeClasses(
            'fixed inset-0 z-50 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
            className,
        )}
        {...props}
        ref={ref}
    />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetPositionVariants = {
    left: 'animate-in slide-in-from-left h-full duration-300',
    right: 'animate-in slide-in-from-right h-full duration-300',
};

export interface DialogContentProps
    extends ComponentPropsWithoutRef<typeof SheetPrimitive.Content> {
    position?: PositionVariants;
}

const SheetContent = forwardRef<ElementRef<typeof SheetPrimitive.Content>, DialogContentProps>(
    ({ position = 'right', className, children, ...props }, ref) => (
        <SheetPortal position={position}>
            <SheetOverlay />
            <SheetPrimitive.Content
                ref={ref}
                onInteractOutside={(e) => e.preventDefault()}
                className={twMergeClasses(
                    'fixed h-screen w-full scale-100 border bg-white p-6 opacity-100 shadow-elevation md:max-w-[440px]',
                    sheetPositionVariants[position],
                    className,
                )}
                {...props}
            >
                {children}
                {/* Added to make the sheet accessible and remove warnings */}
                <SheetPrimitive.Title />
                <SheetPrimitive.Description />
            </SheetPrimitive.Content>
        </SheetPortal>
    ),
);
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={twMergeClasses('flex flex-col space-y-2 text-center sm:text-left', className)}
        {...props}
    />
);
SheetHeader.displayName = 'SheetHeader';

const SheetTitle = forwardRef<
    ElementRef<typeof SheetPrimitive.Title>,
    ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Title
        ref={ref}
        className={twMergeClasses(
            'text-left text-lg font-bold leading-6 tracking-[-0.01em] text-neutral-200 first-letter:uppercase',
            className,
        )}
        {...props}
    />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = forwardRef<
    ElementRef<typeof SheetPrimitive.Description>,
    ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Description
        ref={ref}
        className={twMergeClasses('text-sm font-medium text-neutral-60', className)}
        {...props}
    />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

const SheetClose = forwardRef<
    ElementRef<typeof SheetPrimitive.Close>,
    ComponentPropsWithoutRef<typeof SheetPrimitive.Close>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Close
        ref={ref}
        className={twMergeClasses(
            'flex flex-col items-center justify-center rounded-sm p-1 opacity-70 transition-opacity hover:opacity-100',
            'z-50 cursor-pointer focus:outline-none focus:ring-2 disabled:pointer-events-none',
            className,
        )}
        {...props}
    >
        <CloseIcon className="size-5 fill-neutral-50" />
        <span className="sr-only">Close</span>
    </SheetPrimitive.Close>
));
SheetClose.displayName = SheetPrimitive.Close.displayName;

export {
    Sheet,
    SheetTrigger,
    SheetClose,
    SheetPortal,
    SheetOverlay,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetDescription,
};
