import { ArrowDropDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@in2event/icons';
import { twMergeClasses } from '../../lib';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import type { DayPickerProps, DateRange } from 'react-day-picker';
import 'react-day-picker/style.css';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
    className,
    classNames,
    showOutsideDays = true,
    mode = 'single',
    required = false,
    ...props
}: DayPickerProps) {
    const defaultClassNames = getDefaultClassNames();

    return (
        <DayPicker
            {...props}
            weekStartsOn={1}
            showOutsideDays={showOutsideDays}
            className={twMergeClasses('p-3', className)}
            classNames={{
                root: `${defaultClassNames.root} shadow-lg p-4 rounded-lg`,
                chevron: `${defaultClassNames.chevron} fill-neutral-60 size-6`,
                day: twMergeClasses(`${defaultClassNames.day} relative p-0 text-center text-sm`),
                today: twMergeClasses(
                    'underline-primary-600 font-semibold text-primary-600 underline underline-offset-[3px]',
                ),
                nav: `space-x-1 flex items-center`,
                button_previous: 'absolute left-2 top-2',
                button_next: 'absolute right-2 top-2',
                dropdowns: 'flex flex-1 gap-2 items-center justify-center',
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0 relative space-x-4',
                month_grid: 'w-full border-collapse space-y-1',
                caption_label:
                    'text-center text-sm font-medium flex items-center justify-center w-full',
                weekday:
                    'text-neutral-200/50 uppercase rounded-md w-9 font-semibold text-[11px] leading-4',
                month: mode === 'range' ? 'space-y-3' : 'space-y-5',
                hidden: 'invisible',
                disabled: 'text-[#DFE1E6]',
                outside: 'text-gray-500',
                ...classNames,
            }}
            modifiers={{
                // @ts-ignore
                selection: props.selected,
                // @ts-ignore
                from: props.selected?.from,
                // @ts-ignore
                to: props.selected?.to,
            }}
            modifiersClassNames={{
                selection: twMergeClasses(
                    'font-semibold text-white',
                    mode === 'range' ? 'rounded-none bg-neutral-500' : 'rounded-md bg-neutral-500',
                ),
                from: 'bg-base-900 rounded-l-md',
                to: 'bg-base-900 rounded-r-md',
            }}
            formatters={{
                formatWeekdayName: (weekday) =>
                    weekday.toLocaleString('en-US', { weekday: 'short' }),
            }}
            components={{
                Chevron: (props) => {
                    if (props.orientation === 'left') {
                        return <ChevronLeftIcon {...props} />;
                    }
                    return <ChevronRightIcon {...props} />;
                },
                Dropdown: (props) => {
                    const { options, className, classNames, ...selectProps } = props;

                    return (
                        <div className="relative flex items-center">
                            <select
                                {...selectProps}
                                className={twMergeClasses(
                                    'z-10 flex-1 appearance-none bg-transparent pr-5 text-sm font-bold leading-4 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus:ring-offset-transparent',
                                    className,
                                )}
                            >
                                {options?.map(({ value, label, disabled }) => (
                                    <option key={value} value={value} disabled={disabled}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                            <ArrowDropDownIcon className="pointer-events-none absolute right-0 size-5" />
                        </div>
                    );
                },
            }}
        />
    );
}
Calendar.displayName = 'Calendar';

export { Calendar };
export type { DateRange, Matcher } from 'react-day-picker';
