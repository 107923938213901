import { MoreHorizIcon } from '@in2event/icons';
import type { Row } from '@tanstack/react-table';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { Button } from '../../button';
import { DropdownMenuContent, DropdownMenuRoot, DropdownMenuTrigger } from '../../dropdown';

export interface DataTableRowActionsProps<TData> {
    children: ReactNode;
    row?: Row<TData>;
}

export function DataTableRowActionsMobile<TData>({ children }: DataTableRowActionsProps<TData>) {
    return (
        <DropdownMenuRoot modal={false}>
            <DropdownMenuTrigger
                className="data-[state=open]:bg-neutral-90 data-[state=open]:text-white"
                asChild
            >
                <Button size="icon" variant="subtle">
                    <MoreHorizIcon className="size-5" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                {children}
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
}

export const DataTableRowActionsDesktop = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement>
>((props, ref) => {
    return (
        <div
            {...props}
            ref={ref}
            className="group flex w-18 flex-none items-center justify-end gap-2"
        />
    );
});

DataTableRowActionsDesktop.displayName = 'DataTableRowActionsDesktop';
