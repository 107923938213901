'use client';

import * as React from 'react';
import { format, isBefore, isEqual } from 'date-fns';
import type { DateRange, Matcher } from 'react-day-picker';

import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { twMergeClasses } from '../../lib';
import { Calendar } from '../calendar';
import { DateRangeIcon } from '@in2event/icons';
import { InputText } from '../input-text';
import { Label } from '../label';

export function DateRangePicker({
    fromLabel,
    toLabel,
    selected,
    onChange,
    disabled,
    defaultMonth,
    modifiers,
    canSelectSameDay = false,
}: {
    fromLabel?: string;
    toLabel?: string;
    selected: DateRange | undefined;
    onChange: (date: DateRange | undefined) => void;
    disabled?: Matcher | Matcher[] | undefined;
    defaultMonth?: Date | undefined;
    modifiers?: Record<string, Matcher | Matcher[] | undefined> | undefined;
    canSelectSameDay?: boolean;
}) {
    const [isPopperOpen, setIsPopperOpen] = React.useState(false);

    const [date, setDate] = React.useState<DateRange | undefined>(selected);

    React.useEffect(() => {
        onChange(date);
    }, [date]);

    return (
        <div className={twMergeClasses('grid gap-2')}>
            <Popover open={isPopperOpen} onOpenChange={(isOpen) => setIsPopperOpen(isOpen)}>
                <PopoverTrigger className="relative flex">
                    <div
                        className="absolute inset-0 z-10 w-full"
                        onClick={() => setIsPopperOpen(!isPopperOpen)}
                    />
                    <div className="mr-2 w-1/2">
                        <Label className="mb-2">{fromLabel}</Label>
                        <div className="relative">
                            <InputText
                                name="date-from"
                                value={date?.from ? format(date.from, 'dd-MM-yyyy') : 'DD-MM-YYYY'}
                                disabled
                                className={twMergeClasses(
                                    'w-full',
                                    date?.to && 'disabled:text-base-900',
                                )}
                            />
                            <div className="absolute inset-y-0 right-2 flex items-center">
                                <DateRangeIcon className="size-5 shrink-0 text-neutral-750" />
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2">
                        <Label className="mb-2">{toLabel}</Label>
                        <div className="relative">
                            <InputText
                                name="date-to"
                                value={date?.to ? format(date.to, 'dd-MM-yyyy') : 'DD-MM-YYYY'}
                                disabled
                                className={twMergeClasses(
                                    'w-full',
                                    date?.to && 'disabled:text-base-900',
                                )}
                            />
                            <div className="absolute inset-y-0 right-2 flex items-center">
                                <DateRangeIcon className="size-5 shrink-0 text-neutral-750" />
                            </div>
                        </div>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        mode="range"
                        defaultMonth={defaultMonth ?? date?.from}
                        disabled={disabled}
                        selected={date}
                        onSelect={(date) => {
                            setDate(date);
                            setIsPopperOpen(false);
                        }}
                        modifiers={modifiers}
                        onDayClick={(day) => {
                            // If the day is already selected, deselect it
                            if (date?.from && date?.to) {
                                setDate(undefined);
                                return;
                            }

                            // If the user can select the same day for both from and to
                            if (canSelectSameDay) {
                                setDate((prev) =>
                                    prev?.to
                                        ? { from: day, to: undefined }
                                        : prev?.from
                                          ? { from: prev?.from, to: day }
                                          : { from: day, to: undefined },
                                );

                                return;
                            }

                            setDate((prev) => {
                                if (prev?.from && isEqual(prev.from, day)) {
                                    // Prevent selecting the same day for both from and to
                                    return prev;
                                }
                                if (prev?.from && isBefore(day, prev.from)) {
                                    // If the selected "to" date is before the "from" date, make it the new "from" date
                                    return { from: day, to: undefined };
                                }
                                return prev?.to
                                    ? { from: day, to: undefined }
                                    : prev?.from
                                      ? { from: prev?.from, to: day }
                                      : { from: day, to: undefined };
                            });
                        }}
                        numberOfMonths={2}
                        weekStartsOn={1}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
