import { twMergeClasses } from '../../lib';
import { CloseIcon } from '@in2event/icons';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { Button } from '../button';

const StepsRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses('flex min-h-screen flex-col', className)}
                {...props}
            />
        );
    },
);
StepsRoot.displayName = 'StepsRoot';

const StepsContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <main
                ref={ref}
                className={twMergeClasses('my-15 flex-1 md:mb-20', className)}
                {...props}
            />
        );
    },
);
StepsContent.displayName = 'StepsContent';

const StepsHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <header
                ref={ref}
                className={twMergeClasses(
                    'fixed inset-x-0 top-0 z-20 flex h-15 w-full flex-col bg-white px-4 md:px-6',
                    className,
                )}
                {...props}
            />
        );
    },
);
StepsHeader.displayName = 'StepsHeader';

const StepsFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <footer
                ref={ref}
                className={twMergeClasses(
                    'fixed inset-x-0 bottom-0 h-15 w-full border-t border-t-neutral-1900 bg-white px-4 max-md:shadow-elevation md:h-20 md:px-6',
                    className,
                )}
                {...props}
            />
        );
    },
);
StepsFooter.displayName = 'StepsFooter';

const StepsTitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h3
                ref={ref}
                className={twMergeClasses(
                    'text-sm font-medium text-neutral-250 md:text-base',
                    className,
                )}
                {...props}
            />
        );
    },
);
StepsTitle.displayName = 'StepsTitle';

const StepsProgressText = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses(
                    'text-sm font-bold leading-4 text-neutral-250 md:text-base',
                    className,
                )}
                {...props}
            />
        );
    },
);
StepsProgressText.displayName = 'StepsProgress';

type StepsCancelProps = {
    onCancel: () => void;
    children?: string | React.ReactNode;
};

const StepsCancel = ({ children, onCancel }: StepsCancelProps) => (
    <div>
        <div className="hidden md:flex">
            {!children ? (
                <Button type="button" size="icon" variant="subtle" onClick={onCancel}>
                    <CloseIcon className="size-5" />
                </Button>
            ) : (
                <Button type="button" variant="subtle" onClick={onCancel}>
                    {children}
                </Button>
            )}
        </div>
        <div className="md:hidden">
            <Button type="button" size="icon" variant="subtle" onClick={onCancel}>
                <CloseIcon className="size-5" />
            </Button>
        </div>
    </div>
);

export {
    StepsRoot,
    StepsContent,
    StepsHeader,
    StepsFooter,
    StepsTitle,
    StepsProgressText,
    StepsCancel,
};
