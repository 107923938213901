import { twMergeClasses } from '../../lib';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';
import { useTab } from './use-tab';

const TabsRoot = TabsPrimitive.Root;
const TabsList = TabsPrimitive.List;
const TabsContent = TabsPrimitive.Content;

const TabsTrigger = forwardRef<
    ElementRef<typeof TabsPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
    <TabsPrimitive.Trigger
        className={twMergeClasses(
            'focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap py-4 text-sm font-semibold text-neutral-60 transition-all hover:text-neutral-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            'data-[state=active]:text-primary-600 data-[state=active]:underline data-[state=active]:decoration-[3px] data-[state=active]:underline-offset-[18px]',
            className,
        )}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </TabsPrimitive.Trigger>
));

TabsTrigger.displayName = 'TabsTrigger';

export { TabsRoot, TabsList, TabsTrigger, TabsContent, useTab };
