import { twMergeClasses } from '../../lib';
import type {
    GroupBase,
    OptionProps,
    ValueContainerProps,
    MenuProps,
    PlaceholderProps,
    ControlProps,
} from 'react-select';
import { components } from 'react-select';

import { Loading } from '../loading';

export type OptionType = {
    readonly label: string;
    readonly value: string;
};

export function Option<T, IsMulti extends boolean = false>({
    children,
    className,
    ...props
}: OptionProps<T, IsMulti, GroupBase<T>>) {
    return (
        <components.Option
            {...props}
            className={twMergeClasses(
                'flex w-full cursor-pointer items-center text-neutral-650 hover:bg-neutral-2000',
                className,
                {
                    'bg-neutral-2400': props.isSelected,
                    'bg-white': !props.isSelected,
                },
            )}
        >
            {children}
        </components.Option>
    );
}

export function Placeholder<T, IsMulti extends boolean = false>({
    children,
    ...props
}: PlaceholderProps<T, IsMulti, GroupBase<T>>) {
    return (
        <components.Placeholder {...props}>
            <span
                className={twMergeClasses('truncate text-sm font-medium', {
                    'text-neutral-60': props.isDisabled,
                    'text-neutral-50': !props.isDisabled,
                })}
            >
                {children}
            </span>
        </components.Placeholder>
    );
}

export function ValueContainer<T, IsMulti extends boolean = false>({
    children,
    className,
    ...props
}: ValueContainerProps<T, IsMulti, GroupBase<T>>) {
    return (
        <components.ValueContainer {...props} className={twMergeClasses(className, 'h-[38px]')}>
            {children}
        </components.ValueContainer>
    );
}

export function Menu<T, IsMulti extends boolean = false>({
    children,
    ...props
}: MenuProps<T, IsMulti, GroupBase<T>>) {
    return (
        <components.Menu {...props}>
            <div className={twMergeClasses('rounded-xl shadow-sm')}>{children}</div>
        </components.Menu>
    );
}

export function Control<T, IsMulti extends boolean = false>({
    children,
    ...props
}: ControlProps<T, IsMulti, GroupBase<T>>) {
    return <components.Control {...props}>{children}</components.Control>;
}

export function LoadingIndicator() {
    return <Loading className="size-5" />;
}
