'use client';

import type {
    ColumnDef,
    FilterFnOption,
    RowSelectionOptions,
    SortingOptions,
    TableState,
    VisibilityOptions,
    ExpandedOptions,
    OnChangeFn,
    ColumnFiltersState,
} from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { TableBody, TableCell, TableHead, TableHeader, TableRoot, TableRow } from './components';
import { Loading } from '../loading';
import { twMergeClasses } from '../../lib';

interface FilterFnOptionWrapped<T> {
    filterOptions?: FilterFnOption<T>;
}

interface TableProps<T extends object>
    extends RowSelectionOptions<T>,
        SortingOptions<T>,
        FilterFnOptionWrapped<T>,
        ExpandedOptions<T>,
        VisibilityOptions {
    // TODO: Remove any from ColumnDef once issue is resolved: https://github.com/TanStack/table/issues/4382
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
    columns: ColumnDef<T, any>[];
    data: T[];
    className?: string;
    containerClassName?: string;
    isLoading?: boolean;
    state?: Partial<TableState>;
    getSubRows?: (originalRow: T, index: number) => undefined | T[];
}

/* -------------------------------------------------------------------------------------------------
 * Table
 * -----------------------------------------------------------------------------------------------*/

function Table<T extends object>({
    columns,
    data,
    className,
    containerClassName,
    isLoading,
    ...props
}: TableProps<T>) {
    const columnData = useMemo(() => columns, [columns]);
    const rowData = useMemo(() => data, [data]);

    const table = useReactTable<T>({
        data: rowData,
        columns: columnData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        ...props,
    });

    if (isLoading) {
        return (
            <TableRoot className={twMergeClasses('max-md:[&>thead]:hidden', className)}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={header.column.columnDef.meta?.headerClassName}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <tbody>
                    <TableRow>
                        <TableCell
                            colSpan={table.getVisibleFlatColumns().length}
                            className="h-24 text-center"
                        >
                            <Loading className="size-7" />
                        </TableCell>
                    </TableRow>
                </tbody>
            </TableRoot>
        );
    }

    return (
        <div className={twMergeClasses('overflow-auto', containerClassName)}>
            <TableRoot className={twMergeClasses('max-md:[&>thead]:hidden', className)}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={header.column.columnDef.meta?.headerClassName}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && 'selected'}
                                className="group hover:bg-neutral-2"
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell
                                            key={cell.id}
                                            className={cell.column.columnDef.meta?.cellClassName}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={table.getVisibleFlatColumns().length}
                                className="h-[49px] text-left max-md:px-4"
                            />
                        </TableRow>
                    )}
                </TableBody>
            </TableRoot>
        </div>
    );
}

Table.displayName = 'Table';

export { Table, TableRoot, TableHeader, TableBody, TableRow, TableHead, TableCell };
