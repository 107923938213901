'use client';

import Text from '../typography/text';
import { Conversations } from '.';

export interface ConversationsMessageType {
    isSent?: boolean;
    internal?: boolean;
    createdBy: {
        id: number;
        fullName: string;
    };
    avatar?:
        | {
              alt?: string;
              url: string;
          }
        | undefined;
    message: string;
    createdAt: string;
}

export const Message = ({
    isSent = true,
    internal = true,
    createdBy,
    avatar,
    message,
    createdAt,
}: ConversationsMessageType) => {
    // Find mentions in the text and highlight them by appending a class with span to only that
    // part of the text
    // const textWithMentions = message
    //     .split(' ')
    //     .map((word) => {
    //         if (word.startsWith('@')) {
    //             return (
    //                 <span key={word} className="text-primary-600 mx-1">
    //                     {word}
    //                 </span>
    //             );
    //         }

    //         return `${word} `;
    //     })
    //     .map((word, index) => <span key={index}>{word}</span>);

    // Output: `1 minute ago`.
    const date = new Date(createdAt);

    return (
        <Conversations.MessageContainer isSent={isSent} internal={internal}>
            <Conversations.MessageHeader>
                <Conversations.MessageAvatar
                    name={createdBy.fullName}
                    alt={avatar?.alt}
                    src={avatar?.url}
                />
                <Conversations.MessageMeta>
                    <Conversations.MessageMetaSender>
                        {createdBy.fullName}
                    </Conversations.MessageMetaSender>
                    <Conversations.MessageMetaDivider />
                    <Conversations.MessageMetaDateTime>
                        {timeAgo(date)}
                    </Conversations.MessageMetaDateTime>
                </Conversations.MessageMeta>
            </Conversations.MessageHeader>
            <Conversations.MessageContent>
                <Text>{message}</Text>
            </Conversations.MessageContent>
        </Conversations.MessageContainer>
    );
};

export const timeAgo = (date: Date, lang: string = 'en'): string => {
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return '';
    }

    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
        return getJustNowText(lang);
    }
    if (secondsPast < 3600) {
        return formatTimeAgo(Math.round(secondsPast / 60), 'minute', lang);
    }
    if (secondsPast <= 86400) {
        return formatTimeAgo(Math.round(secondsPast / 3600), 'hour', lang);
    }
    if (secondsPast <= 2592000) {
        return formatTimeAgo(Math.round(secondsPast / 86400), 'day', lang);
    }
    if (secondsPast <= 31536000) {
        return formatTimeAgo(Math.round(secondsPast / 2592000), 'month', lang);
    }
    return formatTimeAgo(Math.round(secondsPast / 31536000), 'year', lang);
};

const getJustNowText = (lang: string): string => {
    const justNowTranslations: { [key: string]: string } = {
        en: 'Just now',
        de: 'Gerade eben',
        nl: 'Zojuist',
    };
    return justNowTranslations[lang] || justNowTranslations['en'];
};

const formatTimeAgo = (value: number, unit: string, lang: string): string => {
    const translations: { [key: string]: { [key: string]: string } } = {
        en: {
            minute: 'minute',
            hour: 'hour',
            day: 'day',
            month: 'month',
            year: 'year',
            ago: 'ago',
        },
        de: {
            minute: 'Minute',
            hour: 'Stunde',
            day: 'Tag',
            month: 'Monat',
            year: 'Jahr',
            ago: 'vor',
        },
        nl: {
            minute: 'minuut',
            hour: 'uur',
            day: 'dag',
            month: 'maand',
            year: 'jaar',
            ago: 'geleden',
        },
    };

    const t = translations[lang] || translations['en'];
    let pluralUnit = t[unit];

    if (lang === 'en' && value > 1) {
        pluralUnit += 's';
    } else if (lang === 'de') {
        if (unit === 'day') pluralUnit = value > 1 ? 'Tage' : 'Tag';
        else if (unit === 'month') pluralUnit = value > 1 ? 'Monate' : 'Monat';
        else if (unit === 'year') pluralUnit = value > 1 ? 'Jahre' : 'Jahr';
    } else if (lang === 'nl') {
        if (value > 1) {
            if (unit === 'day') pluralUnit = 'dagen';
            else if (unit === 'month') pluralUnit = 'maanden';
            else if (unit === 'year') pluralUnit = 'jaren';
        }
    }

    return lang === 'de' ? `${t.ago} ${value} ${pluralUnit}` : `${value} ${pluralUnit} ${t.ago}`;
};
