import { twMergeClasses } from '../../lib';
import { ExpandMoreIcon } from '@in2event/icons';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

const AccordionTrigger = forwardRef<
    ElementRef<typeof AccordionPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            className={twMergeClasses(
                'flex h-8 flex-1 items-center text-lg leading-6 transition-all focus:outline-none [&[data-state=open]>svg]:rotate-180',
                className,
            )}
            {...props}
            ref={forwardedRef}
        >
            <ExpandMoreIcon
                className={twMergeClasses(
                    'h-[18px] w-6 shrink-0 text-neutral-200 transition-transform duration-200',
                )}
            />
            {children}
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

// Content is a div, not a button
const AccordionContent = forwardRef<
    ElementRef<typeof AccordionPrimitive.Content>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Content
        className={twMergeClasses(
            'overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
            className,
        )}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

const AccordionRoot = AccordionPrimitive.Root;
const AccordionItem = AccordionPrimitive.Item;

export { AccordionRoot, AccordionItem, AccordionTrigger, AccordionContent };
