import { Slot } from '@radix-ui/react-slot';
import Heading from '../typography/heading';
import Text from '../typography/text';
import { Button } from '../button';
import type { ReactNode } from 'react';
import { twMergeClasses } from '../../lib';

const NoDataView = ({
    className,
    icon,
    onResetFilters,
    heading,
    description,
    button,
}: {
    className?: string;
    icon: ReactNode;
    onResetFilters?: () => void;
    heading: string;
    description: string;
    button: string;
}) => {
    return (
        <div
            className={twMergeClasses('my-14 flex flex-col items-center justify-center', className)}
        >
            <Slot className="mb-4 size-10 fill-neutral-60">{icon}</Slot>
            <Heading variant="title-2" className="mb-2 text-neutral-60">
                {heading}
            </Heading>
            <Text className="mb-4 text-neutral-60">{description}</Text>
            <Button onClick={onResetFilters}>{button}</Button>
        </div>
    );
};

export default NoDataView;
