import { Messages } from '../messages';
import DynamicIcon from '../dynamic-icon';
import { twMergeClasses } from '../../lib';

interface FormErrorProps {
    message?: string;
    className?: string;
    icon?: string;
}

const FormError = ({ message, className, icon }: FormErrorProps) => {
    if (!message) return null;

    return (
        <Messages
            className={twMergeClasses(className, 'mb-6 flex items-center')}
            variant="destructive"
        >
            {icon ? <DynamicIcon icon={icon} className="mr-2 size-5" /> : null}
            {message}
        </Messages>
    );
};

export default FormError;
