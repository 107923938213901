import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { twMergeClasses } from '../../lib';

const statusLabelVariants = cva(
    'min-h-[16px] whitespace-nowrap rounded px-1.5 text-[11px] font-bold uppercase leading-4 tracking-[0.5px]',
    {
        variants: {
            variant: {
                standard: 'text-neutral-60 bg-neutral-1900',
                primary: 'bg-blue-200 text-blue-700',
                destructive: 'bg-red-100 text-red-700',
                warning: 'bg-orange-300 text-orange-900',
                affirming: 'bg-green-300 text-green-800',
            },
        },
        defaultVariants: {
            variant: 'standard',
        },
    },
);

export interface StatusLabelProps
    extends HTMLAttributes<HTMLSpanElement>,
        VariantProps<typeof statusLabelVariants> {}

const StatusLabel = forwardRef<HTMLSpanElement, StatusLabelProps>(
    ({ variant = 'standard', className, ...props }, ref) => {
        return (
            <span
                ref={ref}
                className={twMergeClasses(statusLabelVariants({ variant }), className)}
                role="status-label"
                {...props}
            />
        );
    },
);

StatusLabel.displayName = 'StatusLabel';

export { StatusLabel, statusLabelVariants };
