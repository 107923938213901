import { ArrowDropDownIcon, UnfoldIcon } from '@in2event/icons';
import type { Column, Table } from '@tanstack/react-table';
import type { HTMLAttributes } from 'react';
import { twMergeClasses } from '../../../lib';
import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuTrigger,
} from '../../dropdown';

interface DataTableColumnHeaderProps<TData, TValue> extends HTMLAttributes<HTMLDivElement> {
    table: Table<TData>;
    column: Column<TData, TValue>;
    enableSorting?: boolean;
    isSorted?: boolean;
    orderByDirection?: 'asc' | 'desc';
    setOrderByDirection?: (value: 'asc' | 'desc') => void;
}

export const DataTableDynamicColumnSortHeader = <TData, TValue>({
    children,
    enableSorting = true,
    isSorted = false,
    orderByDirection = 'asc',
    setOrderByDirection,
}: DataTableColumnHeaderProps<TData, TValue>) => {
    return (
        <DropdownMenuRoot>
            <DropdownMenuTrigger className="flex items-center space-x-2 p-1">
                {children}

                <UnfoldIcon
                    className={twMergeClasses('size-4', { hidden: !enableSorting || isSorted })}
                />
                <ArrowDropDownIcon
                    className={twMergeClasses('size-4', {
                        '-rotate-180': orderByDirection === 'desc',
                        hidden: !enableSorting || !isSorted,
                    })}
                />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" hidden={!enableSorting}>
                <DropdownMenuItem
                    className={twMergeClasses({
                        'text-primary-600': isSorted && orderByDirection === 'asc',
                    })}
                    onClick={() => setOrderByDirection?.('asc')}
                >
                    <ArrowDropDownIcon className="size-5 -rotate-180" />
                    A-Z
                </DropdownMenuItem>
                <DropdownMenuItem
                    className={twMergeClasses({
                        'text-primary-600': isSorted && orderByDirection === 'desc',
                    })}
                    onClick={() => setOrderByDirection?.('desc')}
                >
                    <ArrowDropDownIcon className="size-5" />
                    Z-A
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
};
