import { SearchIcon } from '@in2event/icons';
import { forwardRef } from 'react';
import type { ChangeEvent, ComponentPropsWithoutRef, ElementRef } from 'react';

import { InputText } from '../input-text';
import { twMergeClasses } from '../../lib';

export interface InputSearchProps extends ComponentPropsWithoutRef<typeof InputText> {
    value: string;
    onValueChange: (value: string) => void;
    containerClassName?: string;
}

export const InputSearch = forwardRef<ElementRef<typeof InputText>, InputSearchProps>(
    (
        { value, onValueChange, variant = 'compact', className, containerClassName, ...props },
        forwardedRef,
    ) => {
        // Handle search term change
        const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            onValueChange(value);
        };

        return (
            <div className={twMergeClasses('relative', containerClassName)}>
                <InputText
                    type="text"
                    ref={forwardedRef}
                    value={value}
                    onChange={handleSearchTermChange}
                    className={twMergeClasses(
                        'w-28 border-0 pl-9 pr-4 transition-[width] duration-300 focus:w-48 focus:border-2',
                        { 'w-48': !!value.length },
                        className,
                    )}
                    variant={variant}
                    {...props}
                />
                <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-2.5">
                    <SearchIcon className="size-5 text-neutral-60" />
                </div>
            </div>
        );
    },
);

InputSearch.displayName = 'InputSearch';
