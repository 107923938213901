import { twMergeClasses } from '../../lib';
import ProgressBar from '../progress-bar';
import { StepsCancel } from '../steps';
import Heading from '../typography/heading';
import {
    Children,
    ComponentPropsWithoutRef,
    HTMLAttributes,
    ReactElement,
    isValidElement,
} from 'react';

// Root component for the layout header
type SingleAddLayoutHeaderRootProps = HTMLAttributes<HTMLDivElement>;
const SingleAddLayoutHeaderRoot = ({ className, ...props }: SingleAddLayoutHeaderRootProps) => (
    <div
        className={twMergeClasses(
            'fixed inset-x-0 top-0 z-20 flex h-15 w-full flex-col bg-white px-4 md:px-6',
            className,
        )}
        {...props}
    />
);

// Progress component for the layout header
type SingleAddLayoutHeaderProgressProps = Pick<
    ComponentPropsWithoutRef<typeof Heading>,
    'variant' | 'as'
> &
    HTMLAttributes<HTMLHeadingElement>;
const SingleAddLayoutHeaderProgress = ({
    children,
    ...props
}: SingleAddLayoutHeaderProgressProps) => (
    <Heading variant="title-3" as="h2" {...props}>
        {children}
    </Heading>
);

// Title component for the layout header
type SingleAddLayoutHeaderTitleProps = Pick<
    ComponentPropsWithoutRef<typeof Heading>,
    'variant' | 'as'
> &
    HTMLAttributes<HTMLHeadingElement>;
const SingleAddLayoutHeaderTitle = ({
    children,
    className,
    ...props
}: SingleAddLayoutHeaderTitleProps) => (
    <Heading
        variant="subtitle"
        as="h2"
        className={twMergeClasses('text-neutral-250', className)}
        {...props}
    >
        {children}
    </Heading>
);

// Actions component for the layout header
type SingleAddLayoutHeaderActionsProps = HTMLAttributes<HTMLDivElement>;
const SingleAddLayoutHeaderActions = ({
    className,
    ...props
}: SingleAddLayoutHeaderActionsProps) => (
    <div className={twMergeClasses('flex items-center space-x-2', className)} {...props} />
);

// ProgressBar component for the layout header
type SingleAddLayoutHeaderProgressBarProps = ComponentPropsWithoutRef<typeof ProgressBar>;
const SingleAddLayoutHeaderProgressBar = ({
    className,
    ...props
}: SingleAddLayoutHeaderProgressBarProps) => {
    return <ProgressBar className={twMergeClasses('fixed inset-0 mt-15', className)} {...props} />;
};

// Exported object containing all the components
export const SingleAddLayoutHeader = {
    // Root component
    Root: ({ children, ...props }: SingleAddLayoutHeaderRootProps) => {
        let progress: ReactElement | null = null;
        let title: ReactElement | null = null;
        let actions: ReactElement | null = null;
        let progressBar: ReactElement | null = null;

        // Iterate through children to find specific components
        Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                if (child.type === SingleAddLayoutHeader.Progress) {
                    progress = child;
                } else if (child.type === SingleAddLayoutHeader.Title) {
                    title = child;
                } else if (child.type === SingleAddLayoutHeader.Actions) {
                    actions = child;
                } else if (child.type === SingleAddLayoutHeader.ProgressBar) {
                    progressBar = child;
                }
            }
        });

        return (
            <SingleAddLayoutHeaderRoot {...props}>
                <div className="flex h-full items-center justify-between">
                    <div className="flex items-center gap-x-5">
                        {progress}
                        {title}
                    </div>
                    {actions}
                </div>
                {progressBar}
            </SingleAddLayoutHeaderRoot>
        );
    },
    // Progress component
    Progress: SingleAddLayoutHeaderProgress,
    // Title component
    Title: SingleAddLayoutHeaderTitle,
    // Actions component
    Actions: SingleAddLayoutHeaderActions,
    // Cancel component
    Cancel: StepsCancel,
    // ProgressBar component
    ProgressBar: SingleAddLayoutHeaderProgressBar,
};
