import { twMergeClasses } from '../../lib';
import { CheckIcon } from '@in2event/icons';
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import DynamicIcon from '../dynamic-icon';

const OnboardingStepsRoot = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
    ({ className, ...props }, ref) => (
        <ul
            ref={ref}
            className={twMergeClasses(
                'border-y border-base-300 bg-white md:rounded-lg md:border md:py-2',
                className,
            )}
            {...props}
        />
    ),
);
OnboardingStepsRoot.displayName = 'OnboardingStepsRoot';

const OnboardingStepsItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(
    ({ className, ...props }, ref) => (
        <li
            ref={ref}
            className={twMergeClasses('flex gap-x-4 md:items-center md:px-6', className)}
            {...props}
        />
    ),
);
OnboardingStepsItem.displayName = 'OnboardingStepsItem';

interface RegistrationStepIconProps extends ComponentPropsWithoutRef<typeof DynamicIcon> {
    icon: string;
    disabled?: boolean;
    completed?: boolean;
}

const OnboardingStepsIcon = forwardRef<ElementRef<typeof DynamicIcon>, RegistrationStepIconProps>(
    ({ className, icon, disabled = false, completed = false, ...props }, ref) => {
        return !completed || disabled ? (
            <div className="flex size-9 items-center justify-center rounded-full bg-base-100 max-md:mt-4">
                <DynamicIcon
                    {...props}
                    ref={ref}
                    icon={icon}
                    className={twMergeClasses(
                        'size-5 fill-base-800 text-base-800',
                        {
                            'text-base-500 fill-base-500': disabled,
                        },
                        className,
                    )}
                />
            </div>
        ) : (
            <OnboardingStepsDoneIcon />
        );
    },
);
OnboardingStepsIcon.displayName = 'OnboardingStepsIcon';

const OnboardingStepsDoneIcon = forwardRef<
    ElementRef<typeof CheckIcon>,
    ComponentPropsWithoutRef<typeof CheckIcon>
>(({ className, ...props }, ref) => {
    return (
        <div className="flex size-9 items-center justify-center rounded-full bg-affirmative-200 max-md:mt-4">
            <CheckIcon
                {...props}
                ref={ref}
                className={twMergeClasses('size-6 text-affirmative-800', className)}
            />
        </div>
    );
});
OnboardingStepsDoneIcon.displayName = 'OnboardingStepsDoneIcon';

const OnboardingStepsContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                {...props}
                ref={ref}
                className={twMergeClasses(
                    'flex min-h-16 flex-1 flex-col gap-2 border-t border-base-300 py-4 md:flex-row md:items-center md:justify-between',
                    className,
                )}
            />
        );
    },
);
OnboardingStepsContent.displayName = 'OnboardingStepsContent';

export {
    OnboardingStepsRoot,
    OnboardingStepsItem,
    OnboardingStepsContent,
    OnboardingStepsIcon,
    OnboardingStepsDoneIcon,
};
