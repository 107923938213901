import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';
import type { HtmlHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMergeClasses } from '../../lib';

type TextElementProps = HtmlHTMLAttributes<HTMLParagraphElement>;
type TextElement = React.ElementRef<'p'>;

interface TextAsChildProps {
    asChild: true;
    as?: never;
}
interface TextSpanProps {
    as?: 'span';
    asChild?: false;
}
interface TextDivProps {
    as: 'div';
    asChild?: false;
}
interface TextLabelProps {
    as: 'label';
    asChild?: false;
}
interface TextPProps {
    as: 'p';
    asChild?: false;
}

const textVariants = cva('font-normal', {
    variants: {
        variant: {
            default: 'text-base-900 text-sm',
            headline: 'text-neutral-60 text-sm font-semibold leading-4',
            caption: 'text-neutral-50 text-xs leading-4 font-semibold',
            overline: 'text-4xs font-bold uppercase tracking-6 text-base-700',
            secondary: 'text-neutral-60',
            'body-small': 'text-[13px] leading-5 text-neutral-50',
            'card-label': 'text-neutral-60 text-sm leading-4 font-normal',
            'card-value': 'text-neutral-250 text-sm leading-4 font-medium',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

type TextProps = TextElementProps &
    VariantProps<typeof textVariants> &
    (TextAsChildProps | TextSpanProps | TextDivProps | TextLabelProps | TextPProps);

const Text = forwardRef<TextElement, TextProps>(
    ({ className, as: Tag = 'p', asChild, children, variant, ...props }, ref) => {
        return (
            <Slot
                {...props}
                className={twMergeClasses(textVariants({ variant, className }))}
                ref={ref}
            >
                {asChild ? children : <Tag>{children}</Tag>}
            </Slot>
        );
    },
);

Text.displayName = 'Text';

export default Text;
