'use client';

import { HeaderContext } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '../../checkbox';
import { DataTableCellSelect } from '../data-table-cells/data-table-cell-select';

export const ColumnHeadersSelect = <T,>({ table }: HeaderContext<T, unknown>) => (
    <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
    />
);

export const DataTableColumnSelectConfig = <T,>() => ({
    accessorKey: 'select',
    header: ColumnHeadersSelect<T>,
    cell: DataTableCellSelect<T>,
    enableResizing: false,
    size: 30,
});
