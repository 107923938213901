import { twMergeClasses } from '../../lib';
import { CloseIcon } from '@in2event/icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { forwardRef, useEffect, useRef } from 'react';
import type { ElementRef, ComponentPropsWithoutRef, HTMLAttributes } from 'react';

const Dialog = DialogPrimitive.Root;
const DialogClose = DialogPrimitive.Close;
const DialogPortal = DialogPrimitive.Portal;
const DialogTrigger = DialogPrimitive.Trigger;

const DialogOverlay = forwardRef<
    ElementRef<typeof DialogPrimitive.Overlay>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, forwardedRef) => (
    <DialogPrimitive.Overlay
        ref={forwardedRef}
        className={twMergeClasses(
            'fixed inset-0 z-50 bg-neutral-200/40 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogDismiss = forwardRef<
    ElementRef<typeof DialogPrimitive.Close>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ children, asChild, className, ...props }, forwardedRef) => {
    const body = asChild ? (
        children
    ) : (
        <>
            <CloseIcon className="size-5 fill-neutral-50" />
            <span className="sr-only">Close</span>
        </>
    );

    return (
        <DialogClose
            ref={forwardedRef}
            className={twMergeClasses(
                'absolute right-3.5 top-3.5 flex size-8 flex-col items-center gap-2.5 rounded-sm p-1.5 opacity-70 transition-opacity hover:opacity-100',
                'cursor-pointer focus:outline-none focus:ring-2 disabled:pointer-events-none',
                className,
            )}
            {...props}
        >
            {body}
        </DialogClose>
    );
});
DialogDismiss.displayName = 'DialogDismiss';

const DialogContentPrimitive = forwardRef<
    ElementRef<typeof DialogPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
    <DialogPrimitive.Content
        ref={forwardedRef}
        className={twMergeClasses(
            'fixed z-50 flex w-full flex-col md:max-h-[85vh] md:w-[95vw] md:max-w-[440px]',
            'rounded-lg bg-white shadow-elevation duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 max-md:inset-0 md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:data-[state=closed]:zoom-out-95 md:data-[state=open]:zoom-in-95',
            'data-[state=closed]:slide-out-to-bottom-[48%] data-[state=open]:slide-in-from-bottom-[48%] md:data-[state=closed]:slide-out-to-left-1/2 md:data-[state=closed]:slide-out-to-top-[48%] md:data-[state=open]:slide-in-from-left-1/2 md:data-[state=open]:slide-in-from-top-[48%]',
            className,
        )}
        onInteractOutside={(event) => event.preventDefault()}
        {...props}
    >
        {children}
    </DialogPrimitive.Content>
));
DialogContentPrimitive.displayName = DialogPrimitive.Content.displayName;

const DialogContent = forwardRef<
    ElementRef<typeof DialogPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, ...props }, forwardedRef) => (
    <DialogPortal>
        {/* Move the content inside the overlay to render a dialog with overflow.*/}
        <DialogOverlay>
            <DialogContentPrimitive ref={forwardedRef} {...props}>
                {children}
                <DialogDismiss />
                <DialogPrimitive.Description />
            </DialogContentPrimitive>
        </DialogOverlay>
    </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogTitle = forwardRef<
    ElementRef<typeof DialogPrimitive.Title>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ children, className }, forwardRef) => {
    return (
        <DialogPrimitive.Title
            ref={forwardRef}
            className={twMergeClasses(
                'text-lg font-bold leading-6 tracking-[-0.01em] text-neutral-200 first-letter:uppercase',
                className,
            )}
        >
            {children}
        </DialogPrimitive.Title>
    );
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        aria-label="dialog header"
        className={twMergeClasses(
            'flex h-16 w-full flex-none flex-col border-b border-neutral-1900 p-5 text-left',
            className,
        )}
        {...props}
    />
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={twMergeClasses('mb-18 flex grow flex-col overflow-y-auto p-5', className)}
        {...props}
    />
);
DialogBody.displayName = 'DialogBody';

const DialogFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        aria-label="dialog footer"
        className={twMergeClasses(
            'absolute inset-x-0 bottom-0 flex h-18 w-full flex-none flex-row justify-end gap-2 rounded-b-lg border-t border-neutral-1900 bg-white p-5',
            className,
        )}
        {...props}
    />
);
DialogFooter.displayName = 'DialogFooter';

interface DialogSlimProps extends HTMLAttributes<HTMLDivElement> {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    children: React.ReactNode;
}

const DialogSlim = ({ children, open, onOpenChange, ...props }: DialogSlimProps) => {
    const dialogRef = useRef<HTMLDivElement>(null);

    // When press escape key, close the dialog
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onOpenChange(false);
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onOpenChange]);

    // When dialog is open, prevent body from scrolling
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-200/40 backdrop-blur-sm">
            <div
                ref={dialogRef}
                className="relative flex w-full max-w-[440px] flex-col rounded-lg bg-white shadow-lg"
            >
                <button
                    onClick={() => onOpenChange(false)}
                    className="absolute right-3.5 top-3.5 flex size-8 flex-col items-center gap-2.5 rounded-sm p-1.5 opacity-70 transition-opacity hover:opacity-100"
                >
                    <CloseIcon className="size-5 fill-neutral-50" />
                </button>
                {children}
            </div>
        </div>
    );
};

const useDialogPointer = (open: boolean) => {
    useEffect(() => {
        if (open) {
            // Push the change to the end of the call stack
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = '';
            }, 0);

            return () => {
                clearTimeout(timer);
            };
        }
        document.body.style.pointerEvents = 'auto';
    }, [open]);
};

export {
    Dialog,
    DialogPortal,
    DialogOverlay,
    DialogDismiss,
    DialogContentPrimitive,
    DialogContent,
    DialogTitle,
    DialogClose,
    DialogTrigger,
    DialogHeader,
    DialogBody,
    DialogFooter,
    DialogSlim,
    useDialogPointer,
};
