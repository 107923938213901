import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgDuplicateIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 -960 960 960"
            width="1em"
            fill="#5f6368"
            ref={ref}
            {...props}
        >
            <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgDuplicateIcon);
export default ForwardRef;
