'use client';

import { twMergeClasses } from '../../lib';
import { AlertIcon, CheckCircleSymbol } from '@in2event/icons';
import type { ToastType } from 'react-hot-toast/headless';
import toast, { useToaster, resolveValue } from 'react-hot-toast/headless';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastViewport } from '../toast';

export function Toaster() {
    const { toasts, handlers } = useToaster();
    const { startPause, endPause } = handlers;

    const renderIcon = (type: ToastType) => {
        switch (type) {
            case 'error':
                return <AlertIcon className="mr-2 size-6 fill-warning-600" />;
            case 'success':
                return <CheckCircleSymbol className="mr-2 size-6 fill-affirmative-600" />;
            default:
                return null;
        }
    };

    function dismissToast(toastId: string) {
        toast.remove(toastId);
    }

    return (
        <ToastProvider swipeDirection="up">
            {toasts
                .filter((toast) => toast.visible)
                .map((toast) => {
                    const { id, message, type, duration, className, ariaProps } = toast;

                    return (
                        <Toast
                            key={id}
                            onPause={startPause}
                            onResume={endPause}
                            duration={duration}
                            className={className}
                            {...ariaProps}
                        >
                            <div className="flex w-full items-center gap-1 pr-8">
                                {renderIcon(type)}
                                <ToastDescription>{resolveValue(message, toast)}</ToastDescription>
                            </div>

                            <ToastClose onClick={() => dismissToast(id)} />
                        </Toast>
                    );
                })}

            <ToastViewport />
        </ToastProvider>
    );
}
