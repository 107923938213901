import { twMergeClasses } from '../../lib';
import * as Progress from '@radix-ui/react-progress';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { useEffect, useState, forwardRef } from 'react';

interface ProgressBarProps extends ComponentPropsWithoutRef<typeof Progress.Root> {
    start?: number;
    steps: number;
    activeStep: number;
    delay?: number;
}

const ProgressBar = forwardRef<ElementRef<typeof Progress.Root>, ProgressBarProps>(
    ({ start = 0, steps, activeStep, delay = 250, max = 100, className, style, ...props }, ref) => {
        const [progress, setProgress] = useState(start);

        useEffect(() => {
            const timer = setTimeout(() => setProgress((activeStep / steps) * 100), delay);

            return () => clearTimeout(timer);
        }, [activeStep, steps]);

        return (
            <Progress.Root
                ref={ref}
                role="progressbar"
                className={twMergeClasses('h-base bg-gray-100', className)}
                {...props}
                style={{
                    // Fix overflow clipping in Safari
                    // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
                    transform: 'translateZ(0)',
                    ...style,
                }}
                value={progress}
                max={max}
            >
                <Progress.Indicator
                    className="size-full bg-affirmative-600 transition-transform duration-300 ease-in"
                    style={{ transform: `translateX(-${100 - progress}%)` }}
                />
            </Progress.Root>
        );
    },
);

export default ProgressBar;
