import { ErrorIcon } from '@in2event/icons';
import * as React from 'react';

import { Label } from '../label';
import { capitalizeFirstLetter, twMergeClasses } from '../../lib';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label?: string;
    error?: string;
    hideValidationLabel?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ name, label, className, error, hideValidationLabel = false, ...props }, ref) => {
        return (
            <>
                {label && <Label htmlFor={name}>{capitalizeFirstLetter(label)}</Label>}
                <textarea
                    className={twMergeClasses(
                        'flex h-36 w-full resize-none rounded-md border-2 border-neutral-10 bg-neutral-2100 p-2 text-sm font-medium text-neutral-250 placeholder:text-sm placeholder:font-medium placeholder:text-neutral-50',
                        'hover:bg-neutral-6 focus:border-primary-600 focus:bg-white focus:outline-none focus:ring-0 focus-visible:outline-none',
                        'disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-4 disabled:text-neutral-1100',
                        {
                            'border-destructive-600 focus:border-destructive-600': error,
                        },
                        className,
                    )}
                    ref={ref}
                    id={name}
                    name={name}
                    {...props}
                />
                <div className="pointer-events-none my-1">
                    {error && !hideValidationLabel && (
                        <div className="relative flex items-start">
                            <div className="mr-1 shrink-0">
                                <ErrorIcon className="size-4 fill-destructive-600" />
                            </div>
                            <div className="grow break-words text-xs font-medium text-destructive-600">
                                {error}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    },
);

Textarea.displayName = 'Textarea';

export { Textarea };
