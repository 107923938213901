import { twMergeClasses } from '../../lib';

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={twMergeClasses('animate-pulse rounded-md bg-neutral-1900', className)}
            {...props}
        />
    );
}

export { Skeleton };
