'use client';

import { WarningIcon } from '@in2event/icons';
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from './dialog';
import { Button } from './button';
import Text from './typography/text';

const UnsavedChangesDialog = ({
    open,
    onOpenChange,
    messages,
}: {
    open: boolean;
    onOpenChange: (confirmed: boolean) => void;
    messages: {
        title: string;
        description: string;
        cancel: string;
        confirm: string;
    };
}) => {
    return (
        <Dialog onOpenChange={() => onOpenChange(false)} open={open}>
            <DialogContent>
                <DialogHeader className="border-0">
                    <DialogTitle className="mb-7 inline-flex items-center gap-x-3 text-red-500">
                        <WarningIcon className="size-6 text-red-500" />
                        {messages.title}
                    </DialogTitle>
                </DialogHeader>

                <DialogBody>
                    <Text>{messages.description}</Text>
                </DialogBody>

                <DialogFooter className="border-0">
                    <DialogClose asChild>
                        <Button type="button" variant="subtle" onClick={() => onOpenChange(false)}>
                            {messages.cancel}
                        </Button>
                    </DialogClose>
                    <Button
                        onClick={() => {
                            onOpenChange(true);
                        }}
                        variant="destructive"
                    >
                        {messages.confirm}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default UnsavedChangesDialog;
