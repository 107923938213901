import { ComponentPropsWithoutRef, HTMLAttributes } from 'react';
import { twMergeClasses } from '../../lib';
import Heading from '../typography/heading';
import Text from '../typography/text';

// Define the props for the root component of the layout content
type SingleAddLayoutContentRootProps = HTMLAttributes<HTMLDivElement>;

// Root component of the layout content
const SingleAddLayoutContentRoot = ({ className, ...props }: SingleAddLayoutContentRootProps) => {
    return (
        <div
            className={twMergeClasses(
                'mx-auto flex size-full max-w-5xl flex-col py-28 max-lg:px-4',
                className,
            )}
            {...props}
        />
    );
};

// Define the props for the header component of the layout content
type SingleAddLayoutContentHeaderProps = HTMLAttributes<HTMLDivElement>;

// Header component of the layout content
const SingleAddLayoutContentHeader = ({
    className,
    ...props
}: SingleAddLayoutContentHeaderProps) => {
    return <div className={twMergeClasses('mb-9 space-y-4', className)} {...props} />;
};

// Define the props for the title component of the layout content
type SingleAddLayoutContentTitleProps = Pick<
    ComponentPropsWithoutRef<typeof Heading>,
    'variant' | 'as'
> &
    HTMLAttributes<HTMLHeadingElement>;

// Title component of the layout content
const SingleAddLayoutContentTitle = ({
    children,
    variant = 'large',
    as = 'h3',
    ...props
}: SingleAddLayoutContentTitleProps) => {
    return (
        <Heading variant={variant} as={as} {...props}>
            {children}
        </Heading>
    );
};

// Define the props for the description component of the layout content
type SingleAddLayoutContentDescriptionProps = HTMLAttributes<HTMLParagraphElement>;

// Description component of the layout content
const SingleAddLayoutContentDescription = (props: SingleAddLayoutContentDescriptionProps) => {
    return <Text {...props} />;
};

// Export the layout content components as an object
export const SingleAddLayoutContent = {
    Root: SingleAddLayoutContentRoot, // Root component
    Header: SingleAddLayoutContentHeader, // Header component
    Title: SingleAddLayoutContentTitle, // Title component
    Description: SingleAddLayoutContentDescription, // Description component
};
