import { twMergeClasses } from '../../lib';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

const BannerRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses(
                    'mb-4 flex h-fit items-start gap-x-5 rounded-lg border border-neutral-1800 px-5 py-4 lg:items-center',
                    className,
                )}
                {...props}
            />
        );
    },
);
BannerRoot.displayName = 'BannerRoot';

const BannerIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLSpanElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses(
                    'grid size-10 shrink-0 place-items-center rounded-full',
                    className,
                )}
                {...props}
            />
        );
    },
);
BannerIcon.displayName = 'BannerIcon';

const BannerContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={twMergeClasses(
                    'flex grow items-center justify-between gap-3 max-lg:flex-wrap',
                    className,
                )}
                {...props}
            />
        );
    },
);
BannerContent.displayName = 'BannerContent';

const BannerTitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h3
                ref={ref}
                className={twMergeClasses('text-base font-bold text-neutral-250', className)}
                {...props}
            />
        );
    },
);
BannerTitle.displayName = 'BannerTitle';

const BannerDescription = forwardRef<HTMLDivElement, HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => {
        return (
            <p
                ref={ref}
                className={twMergeClasses('text-sm font-normal text-neutral-60', className)}
                {...props}
            />
        );
    },
);
BannerDescription.displayName = 'BannerDescription';

export { BannerRoot, BannerContent, BannerIcon, BannerTitle, BannerDescription };
