'use client';

import { CheckCircleFilledIcon, ErrorIcon } from '@in2event/icons';
import React from 'react';
import type { SelectInstance, GroupBase } from 'react-select';
import AsyncCreatableSelect, { type AsyncCreatableProps } from 'react-select/async-creatable';
import Creatable, { type CreatableProps } from 'react-select/creatable';

import { LoadingIndicator, Placeholder, ValueContainer, Menu, Control } from './components';
import { Label } from '../label';
import { capitalizeFirstLetter } from '../../lib';

interface InputSelectProps<T, isMulti extends boolean = false>
    extends CreatableProps<T, isMulti, GroupBase<T>> {
    label?: string;
    instanceId: string;
    hideValidationLabel?: boolean;
    error?: string;
    success?: string;
}

interface AsyncInputSelectProps<T, isMulti extends boolean = false>
    extends AsyncCreatableProps<T, isMulti, GroupBase<T>> {
    label?: string;
    instanceId: string;
    hideValidationLabel?: boolean;
    error?: string;
    success?: string;
}

export function InputSelectWithRef<T>(
    props: InputSelectProps<T>,
    ref: React.ForwardedRef<SelectInstance<T>>,
) {
    const { name, label, instanceId, hideValidationLabel, error, success, components, ...rest } =
        props;

    return (
        <>
            {label && <Label htmlFor={instanceId}>{capitalizeFirstLetter(label)}</Label>}
            <Creatable
                ref={ref}
                instanceId={instanceId}
                inputId={instanceId}
                name={name}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        boxShadow: 'none',
                        border: error
                            ? '2px solid #DD3B07'
                            : state.isFocused
                              ? '2px solid #0057FF'
                              : '2px solid rgba(23, 23, 23, 0.1)',
                        background: state.isFocused ? '#FFFFFF' : 'rgba(23, 23, 23, 0.04)',
                        borderRadius: '6px',
                        ':hover': {
                            background: state.isFocused ? '#FFFFFF' : 'rgba(23, 23, 23, 0.06)',
                            border: state.isFocused
                                ? '2px solid #0057FF'
                                : '2px solid rgba(23, 23, 23, 0.1)',
                        },
                    }),
                }}
                components={{
                    ...components,
                    Menu,
                    Control,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    ValueContainer,
                    LoadingIndicator,
                    Placeholder,
                }}
                {...rest}
            />

            {error && !hideValidationLabel && (
                <div className="pointer-events-none my-1">
                    <div className="relative flex items-start">
                        <div className="mr-1 shrink-0">
                            <ErrorIcon className="size-4 fill-red-600" />
                        </div>
                        <div className="grow break-words text-xs font-medium text-red-600">
                            {error}
                        </div>
                    </div>
                </div>
            )}
            {success && !hideValidationLabel && (
                <div className="pointer-events-none my-1">
                    <div className="relative flex items-start">
                        <div className="mr-1 shrink-0">
                            <CheckCircleFilledIcon className="size-4 fill-green-700" />
                        </div>
                        <div className="grow break-words text-xs font-medium text-green-700">
                            {success}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function InputSelectAsyncWithRef<T>(
    props: AsyncInputSelectProps<T>,
    ref: React.ForwardedRef<SelectInstance<T>>,
) {
    const { name, label, instanceId, hideValidationLabel, error, success, components, ...rest } =
        props;

    return (
        <>
            {label && (
                <label
                    htmlFor={instanceId}
                    className="mb-base text-xs font-semibold text-neutral-60"
                >
                    {label}
                </label>
            )}
            <AsyncCreatableSelect
                ref={ref}
                instanceId={instanceId}
                inputId={instanceId}
                noOptionsMessage={() => null}
                loadingMessage={() => null}
                name={name}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        boxShadow: 'none',
                        border: error
                            ? '2px solid #DD3B07'
                            : state.isFocused
                              ? '2px solid #0057FF'
                              : '2px solid rgba(23, 23, 23, 0.1)',
                        background: state.isFocused ? '#FFFFFF' : 'rgba(23, 23, 23, 0.04)',
                        borderRadius: '6px',
                        ':hover': {
                            background: state.isFocused ? '#FFFFFF' : 'rgba(23, 23, 23, 0.06)',
                            border: state.isFocused
                                ? '2px solid #0057FF'
                                : '2px solid rgba(23, 23, 23, 0.1)',
                        },
                    }),
                }}
                components={{
                    ...components,
                    Control,
                    Menu,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    ValueContainer,
                    LoadingIndicator,
                    Placeholder,
                }}
                {...rest}
            />
            <div className="pointer-events-none my-1">
                {error && !hideValidationLabel && (
                    <div className="relative flex items-start">
                        <div className="mr-1 shrink-0">
                            <ErrorIcon className="size-4 fill-red-600" />
                        </div>
                        <div className="grow break-words text-xs font-medium text-red-600">
                            {error}
                        </div>
                    </div>
                )}
                {success && !hideValidationLabel && (
                    <div className="relative flex items-start">
                        <div className="mr-1 shrink-0">
                            <CheckCircleFilledIcon className="size-4 fill-green-700" />
                        </div>
                        <div className="grow break-words text-xs font-medium text-green-700">
                            {success}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export const InputSelect = React.forwardRef(InputSelectWithRef) as <T>(
    props: InputSelectProps<T> & { ref?: React.ForwardedRef<SelectInstance<T>> },
) => ReturnType<typeof InputSelectWithRef>;

export const InputSelectAsync = React.forwardRef(InputSelectAsyncWithRef) as <T>(
    props: AsyncInputSelectProps<T> & { ref?: React.ForwardedRef<SelectInstance<T>> },
) => ReturnType<typeof InputSelectAsyncWithRef>;
