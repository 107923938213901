import { twMergeClasses } from '../../lib';
import type { PropsWithChildren } from 'react';

type InputHintProps = {
    className?: string;
};

export const InputHint = ({ children, className, ...props }: PropsWithChildren<InputHintProps>) => {
    return (
        <span className={twMergeClasses('text-xs text-gray-500', className)} {...props}>
            {children}
        </span>
    );
};
