import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { twMergeClasses } from '../../lib';

const badgeVariants = cva(
    'focus:ring-ring inline-flex justify-center items-center rounded-full border h-4 min-w-4 px-1 text-xs font-bold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'bg-primary-300 text-primary-600 order-transparent',
                secondary: 'bg-base-300 text-base-600 border-transparent',
                destructive: 'bg-destructive-300 text-destructive-800 border-transparent',
                outline: 'text-base-600',
                dark: 'bg-base-800 text-base-100 border-transparent',
                filter: 'bg-primary-600 text-white border-transparent',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return <div className={twMergeClasses(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
