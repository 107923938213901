import { twMergeClasses } from '../../lib';
import type { HTMLProps } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

/* -------------------------------------------------------------------------------------------------
 * Checkbox
 * -----------------------------------------------------------------------------------------------*/

const Checkbox = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
    ({ className, ...props }, forwardedRef) => (
        <input
            role="checkbox"
            type="checkbox"
            ref={forwardedRef}
            className={twMergeClasses(
                'form-checkbox size-3.5 rounded-sm text-primary-600 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border-neutral-200/10 disabled:text-neutral-1600',
                className,
            )}
            {...props}
        />
    ),
);

Checkbox.displayName = 'Checkbox';

/* -------------------------------------------------------------------------------------------------
 * IndeterminateCheckbox
 * -----------------------------------------------------------------------------------------------*/

function IndeterminateCheckbox({
    indeterminate,
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!indeterminate || !ref.current) {
            return;
        }

        ref.current.indeterminate = !rest.checked && indeterminate;
    }, [ref, indeterminate, rest.checked]);

    return (
        <input
            role="checkbox"
            type="checkbox"
            ref={ref}
            className={twMergeClasses(
                'form-checkbox size-3.5 rounded-sm border border-[#767676] text-primary-600 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border-neutral-200/10 disabled:text-neutral-1600',
                rest.className,
            )}
            {...rest}
        />
    );
}

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export { Checkbox, IndeterminateCheckbox };
