'use client';

import {
    SelectContent,
    SelectItem,
    SelectItemText,
    SelectRoot,
    SelectTrigger,
    SelectValue,
} from './select';
import { PaginationNext, PaginationPrevious } from './pagination';
import { Button } from './button';
import { twMergeClasses as cn } from '../lib/accessibility';
import { ExpandMoreIcon } from '@in2event/icons';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { PaginationLinks, PaginationMeta } from '../../types/pagination';

const defaultPageSizes = ['10', '20', '30', '40', '50', 'all'];

interface PaginationNavProps {
    t?: (key: string) => string;
    links: PaginationLinks;
    meta: PaginationMeta;
    className?: string;
    defaultPageSize?: string;
    pageSizes?: string[];
}

const usePagination = (defaultPageSize = '10') => {
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const page = searchParams?.get('page')?.toString();

    // Get the current page size
    const pageSize = searchParams?.get('page_size')?.toString() ?? defaultPageSize;

    // Set page size as a url parameter
    const setPageSize = (pageSize: string) => {
        const params = searchParams ? new URLSearchParams(searchParams) : new URLSearchParams();
        // If page size is all then its 999
        if (pageSize === 'all') {
            pageSize = '9999';
        }
        params.set('page', '1');
        params.set('page_size', pageSize);

        router.push(`${pathname}?${params.toString()}`);
    };

    return {
        page,
        pageSize,
        setPageSize,
    };
};

const PaginationNav = ({
    t,
    links,
    meta,
    className,
    defaultPageSize = defaultPageSizes[0],
    pageSizes = defaultPageSizes,
}: PaginationNavProps) => {
    const searchParams = useSearchParams();
    const pathname = usePathname();

    // Get the current page, page size and set page size
    const { page, pageSize, setPageSize } = usePagination(defaultPageSize);

    // Get the current page
    const currentPage = page ?? meta.current_page;

    const getHref = (page: number) => {
        const params = searchParams ? new URLSearchParams(searchParams) : new URLSearchParams();
        params.set('page', String(page));
        return `${pathname}?${params.toString()}`;
    };

    const prevPage = Number(currentPage) - 1;
    const nextPage = Number(currentPage) + 1;
    let currentPageSize = pageSize;
    // If page size is 9999 then its all
    if (pageSize === '9999') {
        currentPageSize = 'all';
    }

    return (
        <nav
            role="navigation"
            aria-label="pagination"
            className={cn('flex items-center justify-between', className)}
        >
            <div className="flex-1">
                <div className="flex items-center space-x-2">
                    <p className="text-[13px] font-semibold leading-4 text-neutral-50">
                        {t ? t('pagination.rows.per.page') : 'Rows per page'}
                    </p>

                    <SelectRoot value={currentPageSize} onValueChange={setPageSize}>
                        <SelectTrigger className="flex h-6 w-[49px] space-x-1 bg-neutral-1900 text-sm font-semibold text-neutral-60">
                            <SelectValue placeholder={currentPageSize} />
                            <ExpandMoreIcon className="size-4" />
                        </SelectTrigger>
                        <SelectContent side="top" position="popper">
                            {pageSizes.map((pageSize) => (
                                <SelectItem key={pageSize} value={pageSize}>
                                    <SelectItemText>{pageSize}</SelectItemText>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </SelectRoot>
                </div>
            </div>
            <div className="flex items-center space-x-2">
                <div className="flex items-center justify-center text-[13px] font-semibold leading-4 text-neutral-50">
                    {meta.from} - {meta.to} of {meta.total}
                </div>

                <div className="flex items-center space-x-2">
                    <Button size="icon-sm" asChild disabled={links.prev === null}>
                        <PaginationPrevious href={getHref(prevPage)} />
                    </Button>

                    <Button size="icon-sm" asChild disabled={!links.next}>
                        <PaginationNext href={getHref(nextPage)} />
                    </Button>
                </div>
            </div>
        </nav>
    );
};

export { PaginationNav, usePagination };
