import { twMergeClasses } from '../../lib';
import * as icons from '@in2event/icons';
import type { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

type IconType = {
    [key: string]: ForwardRefExoticComponent<
        Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
    >;
};

type MenuIconProps = {
    icon: string;
    isActive?: boolean;
};

export const MenuIcon = ({ icon, isActive }: MenuIconProps) => {
    const iconMap: IconType = icons;
    const Icon = iconMap[icon];

    return (
        <Icon
            className={twMergeClasses(
                'size-5 fill-neutral-1250 text-neutral-1250 group-hover:fill-white group-hover:text-white',
                { 'fill-white text-white': isActive },
            )}
        />
    );
};
