import { ArrowDropDownIcon } from '@in2event/icons';
import type { Column } from '@tanstack/react-table';
import type { HTMLAttributes } from 'react';
import { twMergeClasses } from '../../../lib';
import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuTrigger,
} from '../../dropdown';

interface DataTableColumnHeaderProps<TData, TValue> extends HTMLAttributes<HTMLDivElement> {
    column: Column<TData, TValue>;
    title: string;
}

export const DataTableColumnSortHeader = <TData, TValue>({
    column,
    title,
    className,
}: DataTableColumnHeaderProps<TData, TValue>) => {
    if (!column.getCanSort()) {
        return <div className={twMergeClasses(className)}>{title}</div>;
    }

    return (
        <div className={twMergeClasses('flex items-center space-x-2', className)}>
            <DropdownMenuRoot>
                <DropdownMenuTrigger className="flex items-center space-x-2 p-1">
                    <span className="underline">{title}</span>
                    {column.getIsSorted() === 'desc' && <ArrowDropDownIcon className="size-4" />}
                    {column.getIsSorted() === 'asc' && (
                        <ArrowDropDownIcon className="size-4 -rotate-180" />
                    )}
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
                        <ArrowDropDownIcon className="size-5 -rotate-180" />
                        A-Z
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
                        <ArrowDropDownIcon className="size-5" />
                        Z-A
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenuRoot>
        </div>
    );
};
