import { twMergeClasses } from '../../lib';
import { ErrorIcon } from '@in2event/icons';
import { Slot } from '@radix-ui/react-slot';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

export const ErrorMessageTextBox = forwardRef<
    ElementRef<typeof Slot>,
    ComponentPropsWithoutRef<typeof Slot> & { asChild?: boolean }
>(({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'span';

    return (
        <div
            className="my-2 flex items-start gap-2 rounded-md bg-red-100 px-3 py-2.5"
            role="message-text"
        >
            <ErrorIcon className="size-4 shrink-0 fill-red-600" />
            <Comp
                ref={ref}
                className={twMergeClasses(
                    'grow break-words text-xs font-medium text-red-600',
                    className,
                )}
                {...props}
            />
        </div>
    );
});

ErrorMessageTextBox.displayName = 'ErrorMessageTextBox';
