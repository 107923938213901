import { twMergeClasses } from '../../lib';
import * as icons from '@in2event/icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

const MenuItemRoot = forwardRef<
    ElementRef<typeof Collapsible.Root>,
    ComponentPropsWithoutRef<typeof Collapsible.Root>
>((props, forwardedRef) => <Collapsible.Root {...props} ref={forwardedRef} />);

MenuItemRoot.displayName = 'MenuItemRoot';

const MenuItemTrigger = forwardRef<
    ElementRef<typeof Collapsible.Trigger>,
    ComponentPropsWithoutRef<typeof Collapsible.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
    <Collapsible.Trigger
        className={twMergeClasses(
            'group flex h-10 w-full shrink-0 items-center gap-4 rounded px-3 py-0 text-sm font-semibold leading-8 text-[#999] data-[state=open]:text-white',
            className,
        )}
        {...props}
        ref={forwardedRef}
    >
        {children}
        <icons.ChevronRightIcon className="size-5 fill-neutral-2250 group-data-[state=open]:rotate-90" />
    </Collapsible.Trigger>
));

MenuItemTrigger.displayName = 'MenuItemTrigger';

const MenuItemContent = forwardRef<
    ElementRef<typeof Collapsible.Content>,
    ComponentPropsWithoutRef<typeof Collapsible.Content>
>((props, forwardedRef) => <Collapsible.Content {...props} ref={forwardedRef} />);

MenuItemContent.displayName = 'MenuItemContent';

export { MenuItemRoot, MenuItemTrigger, MenuItemContent };
