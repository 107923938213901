import { autoUpdate, offset, size, useFloating, useInteractions } from '@floating-ui/react';
import { twMergeClasses } from '../../lib';
import { useCombobox, useMultipleSelection } from 'downshift';
import type { ChangeEvent, ComponentPropsWithoutRef, ForwardedRef, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import { InputText } from '../input-text';

type MultiSelectProps<T> = {
    ref?: Ref<HTMLInputElement>;
    loading?: boolean;
    options: T[];
    inputValue?: string | undefined;
    onValueChange?: (value: string) => void;
    selectedItems: T[];
    setSelectedItems: (items: T[]) => void;
    itemToString: (item: T | null) => string;
    renderOption: (option: T) => ReactNode;
    renderSelectedItem: (option: T) => string;
    initialSelectedItems?: T[] | undefined;
    inputProps: Omit<ComponentPropsWithoutRef<typeof InputText>, 'ref'>;
};

export function MultiSelectInner<T>(
    {
        options,
        renderOption,
        renderSelectedItem,
        itemToString,
        inputValue,
        onValueChange,
        selectedItems,
        setSelectedItems,
        inputProps,
    }: MultiSelectProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
) {
    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
        selectedItems,
        onStateChange({ selectedItems: newSelectedItems, type }) {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    newSelectedItems && setSelectedItems(newSelectedItems);
                    break;
                default:
                    break;
            }
        },
    });
    const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } =
        useCombobox<T>({
            items: options,
            itemToString,
            defaultHighlightedIndex: 0, // after selection, highlight the first item.
            selectedItem: null,
            inputValue,
            stateReducer(state, actionAndChanges) {
                const { changes, type } = actionAndChanges;

                switch (type) {
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                        return {
                            ...changes,
                            isOpen: true, // keep the menu open after selection.
                            highlightedIndex: 0, // with the first option highlighted.
                        };
                    default:
                        return changes;
                }
            },
            onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
                switch (type) {
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                    case useCombobox.stateChangeTypes.InputBlur:
                        if (newSelectedItem) {
                            setSelectedItems([...selectedItems, newSelectedItem]);
                            onValueChange && onValueChange('');
                        }
                        break;

                    case useCombobox.stateChangeTypes.InputChange:
                        newInputValue && onValueChange && onValueChange(newInputValue);

                        break;
                    default:
                        break;
                }
            },
        });

    const { refs, floatingStyles } = useFloating<HTMLDivElement>({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        middleware: [
            offset(4),
            size({
                apply({ rects, availableHeight, elements }) {
                    requestAnimationFrame(() => {
                        Object.assign(elements.floating.style, {
                            width: `${rects.reference.width}px`,
                            maxHeight: `${availableHeight - 64}px`,
                        });
                    });
                },
            }),
        ],
    });

    const { getFloatingProps, getReferenceProps } = useInteractions();

    return (
        <div>
            {selectedItems.length > 0 && (
                <div className="mb-2 inline-flex flex-wrap items-center gap-2 bg-white">
                    {selectedItems.map((selectedItemForRender, index) => {
                        return (
                            <span
                                className="rounded-sm bg-gray-100 px-2 text-sm"
                                key={`selected-item-${index}`}
                                {...getSelectedItemProps({
                                    selectedItem: selectedItemForRender,
                                    index,
                                })}
                            >
                                {renderSelectedItem(selectedItemForRender)}
                                <span
                                    className="cursor-pointer px-2"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeSelectedItem(selectedItemForRender);
                                    }}
                                >
                                    &#10005;
                                </span>
                            </span>
                        );
                    })}
                </div>
            )}

            <div ref={refs.setReference} {...getReferenceProps()}>
                <InputText
                    {...getInputProps(
                        getDropdownProps({
                            preventKeyAction: isOpen,
                            ref,
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                onValueChange && onValueChange(e.target.value);
                            },
                            autoComplete: 'off', // Disable Chrome's autocomplete suggestions
                            ...inputProps,
                        }),
                    )}
                />
            </div>
            <div
                ref={refs.setFloating}
                {...getFloatingProps({
                    className: twMergeClasses(
                        'z-50 mt-base overflow-y-auto rounded-md bg-white shadow-elevation',
                    ),
                    style: {
                        ...floatingStyles,
                    },
                })}
            >
                <ul
                    {...getMenuProps({
                        className: twMergeClasses({
                            hidden: !(isOpen && options.length),
                        }),
                    })}
                >
                    {isOpen &&
                        options.map((item, index) => (
                            <li
                                key={index}
                                {...getItemProps({
                                    index,
                                    item,
                                })}
                                className={twMergeClasses(
                                    {
                                        'bg-blue-300': highlightedIndex === index,
                                        'font-bold': selectedItem === item,
                                    },
                                    'flex cursor-pointer flex-col px-3 py-2',
                                )}
                            >
                                {renderOption(item)}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}

// type assertion that restores the original function signature.
const MultiSelect = forwardRef(MultiSelectInner) as <T>(
    props: MultiSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof MultiSelectInner>;

export { MultiSelect, type MultiSelectProps };
