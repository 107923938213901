'use client';

import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { create, extend } from '../components';
import { twMergeClasses } from '../../lib';
import { VisibilityIcon, VisibilityOffIcon } from '@in2event/icons';
import { InputText } from '../input-text';
import { Button } from '../button';
import { Avatar } from '../avatar';
import { Message, timeAgo } from './message';

const Root = extend(create('div'), {
    className: 'relative flex w-full flex-col overflow-hidden h-full',
});
Root.displayName = 'ConversationsRoot';

const Content = extend(create('div'), {
    className: 'min-h-0 overflow-y-auto pb-30 flex-1 px-4 py-2.5',
});
Content.displayName = 'ConversationsContent';

const MessageContainer = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & {
        isSent?: boolean;
        internal?: boolean;
    }
>(({ children, className, isSent = true, internal = true, ...props }, forwardedRef) => {
    return (
        <div
            ref={forwardedRef}
            className={twMergeClasses(
                'my-2.5 w-fit rounded-2xl p-4 text-sm ',
                isSent && 'max-md:w-11/12',
                isSent ? 'bg-primary-200' : 'bg-base-200',
                isSent ? 'ml-auto' : 'mr-auto',
                isSent ? 'rounded-br-none' : 'rounded-bl-none',
                internal ? 'bg-warning-300' : 'bg-primary-200',
                !internal && !isSent && 'bg-base-200',
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
});
MessageContainer.displayName = 'ConversationsMessageContainer';

const MessageHeader = extend(create('div'), {
    className: 'mb-base flex items-center space-x-2',
});
MessageHeader.displayName = 'ConversationsMessageHeader';

const MessageAvatar = (props: ComponentPropsWithoutRef<typeof Avatar>) => {
    return <Avatar variant={Avatar.variant.xxs} {...props} />;
};

const MessageMeta = extend(create('ul'), {
    className: 'text-base-900 mb-1 flex space-x-2 text-sm',
});
MessageMeta.displayName = 'ConversationsMessageMeta';

const MessageMetaSender = extend(create('li'), {
    className: 'font-semibold',
});
MessageMetaSender.displayName = 'ConversationsMessageMetaSender';

const MessageMetaDateTime = extend(create('li'), {
    className: 'text-neutral-50',
});
MessageMetaDateTime.displayName = 'ConversationsMessageMetaDateTime';

const MessageMetaDivider = extend(create('li'), {
    className: 'text-neutral-50 text-base font-semibold flex items-center',
    children: '•',
    style: { lineHeight: '1' }, // This ensures the bullet is vertically centered
});
MessageMetaDivider.displayName = 'ConversationsMessageMetaDivider';

const MessageContent = extend(create('div'), {
    className: 'ml-8',
});
MessageContent.displayName = 'ConversationsMessageContent';

const Footer = extend(create('div'), {
    className:
        'border-base-300 shadow-elevation relative z-10 h-auto w-full border-t bg-white p-4 md:px-6',
});
Footer.displayName = 'ConversationsFooter';

const TypeSwitchContainer = extend(create('div'), {
    className: 'mb-2 flex flex-col max-md:space-y-2 md:flex-row md:space-x-[10px]',
});
TypeSwitchContainer.displayName = 'ConversationsTypeSwitchContainer';

const TypeSwitchButton = forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean }
>(({ className, active, ...props }, forwardedRef) => {
    return (
        <button
            ref={forwardedRef}
            type="button"
            className={twMergeClasses(
                'bg-neutral-6 px-3 py-1 text-xs text-neutral-200/60',
                active && 'bg-neutral-400 text-white',
                className,
            )}
            {...props}
        />
    );
});
TypeSwitchButton.displayName = 'ConversationsTypeSwitchButton';

const TypeSwitchButtonGroup = extend(create('div'), {
    className: 'flex items-center overflow-hidden rounded-md',
});
TypeSwitchButtonGroup.displayName = 'ConversationsTypeSwitchButtonGroup';

const TypeSwitchTip = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & { internal?: boolean }
>(({ children, internal = false, ...props }, forwardedRef) => {
    return (
        <div
            ref={forwardedRef}
            className="flex items-center space-x-2 text-xs text-neutral-50 max-md:hidden"
            {...props}
        >
            {internal ? (
                <VisibilityOffIcon className="size-4 fill-current" />
            ) : (
                <VisibilityIcon className="size-4 fill-current" />
            )}
            <span>{children}</span>
        </div>
    );
});
TypeSwitchTip.displayName = 'ConversationsTypeSwitchTip';

const SendMessageContainer = extend(create('div'), {
    className: 'flex flex-1 items-center space-x-2',
});
SendMessageContainer.displayName = 'ConversationsSendMessageContainer';

const SendMessageInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
    ({ className, ...props }, forwardedRef) => {
        return (
            <InputText
                ref={forwardedRef}
                name="conversation-respond"
                autoComplete="conversation-respond"
                className={twMergeClasses('grow', className)}
                {...props}
            />
        );
    },
);
SendMessageInput.displayName = 'ConversationsSendMessageInput';

const SendMessageButton = forwardRef<
    ElementRef<typeof Button>,
    ComponentPropsWithoutRef<typeof Button> & {
        internal?: boolean;
    }
>(({ className, internal = true, ...props }, forwardedRef) => {
    return (
        <Button
            ref={forwardedRef}
            variant={internal ? 'primary' : 'warning'}
            size="lg"
            className={twMergeClasses(
                'text-neutral-250 max-md:p-2',
                internal && 'text-white',
                className,
            )}
            {...props}
        />
    );
});
SendMessageButton.displayName = 'ConversationsSendMessageButton';

// Example usage:
// <Conversations.Root>
//     <Conversations.Content>
//         <Conversations.MessageContainer isSent>
//             <Conversations.MessageHeader>
//                 <Conversations.MessageAvatar />
//                 <Conversations.MessageMeta>
//                     <Conversations.MessageMetaSender />
//                     <Conversations.MessageMetaDivider />
//                     <Conversations.MessageMetaDateTime />
//                 </Conversations.MessageMeta>
//             </Conversations.MessageHeader>
//             <Conversations.MessageContent>
//                     {message}
//             </Conversations.MessageContent>
//         </Conversations.MessageContainer>
//     </Conversations.Content>
//     <Conversations.Footer>
//         <Conversations.TypeSwitchContainer>
//              <Conversations.TypeSwitchButtonGroup>
//                  <Conversations.TypeSwitchButton active>Internal</Conversations.TypeSwitchButton>
//                  <Conversations.TypeSwitchButton>Shared</Conversations.TypeSwitchButton>
//              </Conversations.TypeSwitchButtonGroup>
//              <Conversations.TypeSwitchTip>
//                  Shared messages are visible to members of your team and this attendee.
//              </Conversations.TypeSwitchTip>
//         </Conversations.TypeSwitchContainer>
//         <Conversations.SendMessageContainer>
//             <Conversations.SendMessageInput />
//             <Conversations.SendMessageButton />
//         </Conversations.SendMessageContainer>
//     </Conversations.Footer>

export const Conversations = {
    Root,
    Content,
    Message,
    MessageContainer,
    MessageHeader,
    MessageAvatar,
    MessageMeta,
    MessageMetaSender,
    MessageMetaDateTime,
    MessageMetaDivider,
    MessageContent,
    Footer,
    TypeSwitchContainer,
    TypeSwitchButtonGroup,
    TypeSwitchButton,
    TypeSwitchTip,
    SendMessageContainer,
    SendMessageInput,
    SendMessageButton,
    timeAgo,
};
