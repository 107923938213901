'use client';

import { ExpandMoreIcon } from '@in2event/icons';
import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuTrigger,
} from '../dropdown';
import { Button } from '../button';
import { Badge } from '../badge';
import { Checkbox } from '../checkbox';

export interface FilterItem {
    value: string;
    label: string;
}

interface DataTableFilterProps {
    name: string;
    items: FilterItem[];
    value: string[];
    onChange: (value: string[]) => void;
}

export const DataTableFilter = ({ name, items, value, onChange }: DataTableFilterProps) => {
    const onSelection = (selected: string) => {
        if (value.includes(selected)) {
            onChange(value.filter((item) => item !== selected));
        } else {
            onChange([...value, selected]);
        }
    };

    return (
        <DropdownMenuRoot>
            <DropdownMenuTrigger asChild>
                <Button className="aria-[expanded=true]:bg-neutral-90 aria-[expanded=true]:fill-white aria-[expanded=true]:text-white">
                    {name}{' '}
                    {value.length > 0 ? <Badge variant="filter">{value.length}</Badge> : null}
                    <ExpandMoreIcon className="size-5" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-[240px]">
                <div className="max-h-[250px] overflow-y-auto">
                    {items.map((item) => (
                        <DropdownMenuItem
                            key={item.value}
                            className="gap-2 first-letter:capitalize"
                            onSelect={(e) => {
                                e.preventDefault();
                                onSelection(item.value);
                            }}
                        >
                            <Checkbox checked={value.includes(item.value)} readOnly />
                            <span className="ml-2">{item.label}</span>
                        </DropdownMenuItem>
                    ))}
                </div>
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
};
