'use client';

import { twMergeClasses } from '../../lib';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from 'react';
import { forwardRef, useState } from 'react';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipContent = forwardRef<
    ElementRef<typeof TooltipPrimitive.Content>,
    ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={twMergeClasses(
            'z-50 overflow-hidden rounded bg-neutral-250 px-1.5 py-1 text-[13px] leading-5 text-white shadow-elevation animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
        )}
        {...props}
    />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TooltipProps extends ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
    children: ReactNode;
    content: string;
    skipDelayDuration?: number;
    delayDuration?: number;
    sideOffset?: number;
}

// Ref: https://github.com/radix-ui/primitives/issues/955#issuecomment-1798201143
// Issue: The Tooltip component isn't working on iOS Safari / Mobile.
const Tooltip = ({
    children,
    content,
    skipDelayDuration,
    delayDuration = 0,
    sideOffset = 5,
    ...props
}: TooltipProps) => {
    const [open, setOpen] = useState(false);

    return (
        <TooltipProvider skipDelayDuration={skipDelayDuration}>
            <TooltipRoot open={open} onOpenChange={setOpen} delayDuration={delayDuration}>
                <TooltipTrigger
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                    onFocus={() => setTimeout(() => setOpen(true), 0)} // timeout needed to run this after onOpenChange to prevent bug on mobile
                    onBlur={() => setOpen(false)}
                    asChild
                >
                    {children}
                </TooltipTrigger>
                <TooltipPrimitive.Portal>
                    <TooltipContent sideOffset={sideOffset} {...props}>
                        {content}
                        {/* <TooltipArrow className="fill-neutral-250" /> */}
                    </TooltipContent>
                </TooltipPrimitive.Portal>
            </TooltipRoot>
        </TooltipProvider>
    );
};

export {
    Tooltip,
    TooltipRoot,
    TooltipTrigger,
    TooltipContent,
    TooltipProvider,
    TooltipArrow,
    TooltipPrimitive,
};
