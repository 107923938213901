import { EditNoteIcon } from '@in2event/icons';
import { OnChangeFn, RowSelectionState, VisibilityState } from '@tanstack/react-table';
import { twMergeClasses } from '../../lib';
import { Tooltip } from '../tooltip';
import { Button } from '../button';

interface DataTableRowSelectionToggleProps {
    content: string;
    useContext: () => {
        columnVisibility?: VisibilityState;
        setColumnVisibility: OnChangeFn<VisibilityState>;
        setRowSelection: OnChangeFn<RowSelectionState>;
    };
}

export const DataTableRowSelectionToggle = ({
    content,
    useContext,
}: DataTableRowSelectionToggleProps) => {
    const { columnVisibility, setColumnVisibility, setRowSelection } = useContext();

    const toggleVisibility = () => {
        // Clear row selection when the column is hidden
        if (columnVisibility?.select) {
            setRowSelection({});
        }

        setColumnVisibility({
            ...columnVisibility,
            select: !columnVisibility?.select,
        });
    };

    return (
        <Tooltip content={content} collisionPadding={40}>
            <Button
                className={twMergeClasses('hover:bg-primary-200 hover:text-current', {
                    'bg-neutral-90 text-white': columnVisibility?.select,
                })}
                onClick={toggleVisibility}
                size="icon"
            >
                <EditNoteIcon className="size-5" />
            </Button>
        </Tooltip>
    );
};
