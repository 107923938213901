import * as Collapsible from '@radix-ui/react-collapsible';
import { twMergeClasses } from '../../lib';
import { ExpandMoreIcon } from '@in2event/icons';
import Heading from '../typography/heading';

const CollapsibleRoot = Collapsible.Root;
const CollapsibleTrigger = Collapsible.Trigger;
const CollapsibleContent = Collapsible.Content;

interface ComponentProps {
    children: React.ReactNode;
    title: React.ReactNode;
    defaultOpen?: boolean;
    className?: string;
    actionsDropdown?: React.ReactNode;
}

const SheetCollapsible = ({
    children,
    title,
    defaultOpen = true,
    className,
    actionsDropdown,
}: ComponentProps) => {
    return (
        <CollapsibleRoot
            defaultOpen={defaultOpen}
            className={twMergeClasses('border-b px-6 py-5 [&[data-state=closed]]:pb-0', className)}
        >
            <div className="group mb-5 inline-flex w-full justify-between">
                <CollapsibleTrigger className="grow text-left text-[11px] font-bold uppercase leading-3 tracking-[0.44px]">
                    {title}
                </CollapsibleTrigger>
                {actionsDropdown ? (
                    <div className="flex items-center space-x-2">
                        {actionsDropdown}
                        <CollapsibleTrigger>
                            <ExpandMoreIcon className="size-5 shrink-0 group-data-[state=open]:rotate-180" />
                        </CollapsibleTrigger>
                    </div>
                ) : (
                    <CollapsibleTrigger>
                        <ExpandMoreIcon className="size-5 shrink-0 group-data-[state=open]:rotate-180" />
                    </CollapsibleTrigger>
                )}
            </div>
            <CollapsibleContent className="text-sm font-medium text-neutral-250">
                {children}
            </CollapsibleContent>
        </CollapsibleRoot>
    );
};

const HowItWorksCollapsible = ({
    children,
    title,
    defaultOpen = true,
    className,
}: ComponentProps) => {
    return (
        <CollapsibleRoot
            defaultOpen={defaultOpen}
            className={twMergeClasses(
                'border-b px-6 [&[data-state=closed]]:max-h-16 [&[data-state=closed]]:pb-0 [&[data-state=open]]:py-5',
                className,
            )}
        >
            <CollapsibleTrigger className="mb-4 flex w-full flex-1 items-center justify-between transition-all focus:outline-none data-[state=closed]:h-16 [&[data-state=open]>svg]:rotate-180">
                <span className="text-base font-semibold leading-4 text-neutral-250">{title}</span>
                <ExpandMoreIcon className="size-6 shrink-0 text-neutral-200 transition-transform duration-200" />
            </CollapsibleTrigger>
            <CollapsibleContent className="text-sm font-medium text-neutral-250">
                {children}
            </CollapsibleContent>
        </CollapsibleRoot>
    );
};

export {
    CollapsibleRoot,
    CollapsibleTrigger,
    CollapsibleContent,
    SheetCollapsible,
    HowItWorksCollapsible,
};
