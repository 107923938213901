import type { HTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMergeClasses } from '../../../lib';

const TableRoot = forwardRef<HTMLTableElement, HTMLAttributes<HTMLTableElement>>(
    ({ className, ...props }, ref) => (
        <table
            ref={ref}
            className={twMergeClasses('w-full caption-bottom text-sm text-neutral-250', className)}
            {...props}
        />
    ),
);
TableRoot.displayName = 'TableRoot';

const TableHeader = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <thead ref={ref} className={twMergeClasses('[&_tr]:border-b', className)} {...props} />
    ),
);
TableHeader.displayName = 'TableHeader';

const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <tbody
            ref={ref}
            className={twMergeClasses('[&_tr:last-child]:border-0', className)}
            {...props}
        />
    ),
);
TableBody.displayName = 'TableBody';

const TableFooter = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => <tfoot ref={ref} className={className} {...props} />,
);
TableFooter.displayName = 'TableFooter';

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
    ({ className, ...props }, ref) => (
        <tr
            ref={ref}
            className={twMergeClasses(
                'group border-b border-neutral-10 transition-colors data-[state=selected]:bg-blue-100',
                className,
            )}
            {...props}
        />
    ),
);
TableRow.displayName = 'TableRow';

const TableHead = forwardRef<HTMLTableCellElement, ThHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <th
            ref={ref}
            className={twMergeClasses(
                'h-[49px] text-left align-middle font-semibold leading-4 text-neutral-50 md:py-4 md:pr-8 md:last:pr-4 [&:has([role=checkbox])]:w-[30px] [&:has([role=checkbox])]:pr-4 max-md:[&:has([role=checkbox])]:text-center [&>[role=checkbox]]:-translate-y-px',
                className,
            )}
            {...props}
        />
    ),
);
TableHead.displayName = 'TableHead';

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <td
            ref={ref}
            className={twMergeClasses(
                'py-3 align-middle md:pr-8 md:last:pr-4 [&:has([role=checkbox])]:w-[30px] [&:has([role=checkbox])]:pr-4 [&>[role=checkbox]]:-translate-y-px',
                className,
            )}
            {...props}
        />
    ),
);
TableCell.displayName = 'TableCell';

const TableCaption = forwardRef<HTMLTableCaptionElement, HTMLAttributes<HTMLTableCaptionElement>>(
    ({ className, ...props }, ref) => (
        <caption ref={ref} className={twMergeClasses('mt-4 text-sm', className)} {...props} />
    ),
);
TableCaption.displayName = 'TableCaption';

export {
    TableRoot,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
    TableCaption,
};
