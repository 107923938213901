import type {
    ColumnDef,
    ColumnFiltersState,
    OnChangeFn,
    SortingState,
    TableState,
    VisibilityState,
    Updater,
    Row,
    ExpandedState,
} from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import type { ReactNode } from 'react';
import { Fragment, useEffect } from 'react';
import { TableBody, TableCell, TableHead, TableHeader, TableRoot, TableRow } from '../table';
import { Skeleton } from '../skeleton';
import { twMergeClasses } from '../../lib';
interface DataTableProps<TData, TValue = unknown> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    className?: string;
    state?: Partial<
        Pick<
            TableState,
            'rowSelection' | 'columnVisibility' | 'columnFilters' | 'sorting' | 'expanded'
        >
    >;
    setRowSelection?: OnChangeFn<{}>;
    setColumnVisibility?: OnChangeFn<VisibilityState>;
    setColumnFilters?: OnChangeFn<ColumnFiltersState>;
    setExpanded?: OnChangeFn<ExpandedState>;
    setSorting?: OnChangeFn<SortingState>;
    isLoading?: boolean;
    // User can provide a custom empty state component
    emptyState?: ReactNode;
    // User can provide a callback to be called when the state changes
    onStateChange?: ((updater: Updater<TableState>) => void) | undefined;
    // Callback to be called when the row model changes
    onRowModelChange?: (rowModel: Row<TData>[]) => void | undefined;
    onRowClick?: (row: Row<TData>) => void;
    getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
    getSubRows?: (originalRow: TData, index: number) => undefined | TData[];
    highlightedRowId?: string;
    collapsibleRows?: Record<string, boolean>;
    setCollapsibleRows?: OnChangeFn<Record<string, boolean>>;
    renderCollapsedRow?: (row: Row<TData>) => ReactNode;
}

export function DataTable<TData, TValue = unknown>({
    columns,
    data,
    className,
    state,
    setRowSelection,
    setColumnVisibility,
    setColumnFilters,
    setExpanded,
    setSorting,
    isLoading = false,
    emptyState,
    onStateChange,
    onRowModelChange,
    onRowClick,
    getRowId,
    getSubRows,
    highlightedRowId,
    collapsibleRows,
    renderCollapsedRow,
}: DataTableProps<TData, TValue>) {
    const table = useReactTable<TData>({
        data,
        columns,
        state,
        onExpandedChange: setExpanded,
        onStateChange: onStateChange,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowId,
        getSubRows,
    });

    // On table rowModel change, log the new row model outside of the render
    const debouncedRowModelChange = debounce((rowModel: Row<TData>[]) => {
        onRowModelChange?.(rowModel);
    }, 300);

    useEffect(() => {
        debouncedRowModelChange(table.getRowModel().rows);
    }, [table.getRowModel().rows]);

    if (emptyState && !isLoading && (!data.length || table.getRowModel().rows.length === 0)) {
        // If there is no data, render the empty state component
        return <>{emptyState}</>;
    }

    return (
        <TableRoot className={className}>
            <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            // Get the last header to stretch it
                            const isLastHeader =
                                headerGroup.headers.indexOf(header) ===
                                headerGroup.headers.length - 1;

                            return (
                                <TableHead key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHeader>
            {!isLoading ? (
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => {
                            if (collapsibleRows?.[row.id]) {
                                return (
                                    <Fragment key={row.id}>
                                        <TableRow
                                            data-state={row.getIsSelected() && 'selected'}
                                            onClick={() => onRowClick?.(row)}
                                            className={twMergeClasses('border-b-0', {
                                                'bg-blue-100': row.id === highlightedRowId,
                                            })}
                                        >
                                            {row.getVisibleCells().map((cell) => (
                                                <TableCell key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {renderCollapsedRow?.(row)}
                                    </Fragment>
                                );
                            }

                            return (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && 'selected'}
                                    onClick={() => onRowClick?.(row)}
                                    className={twMergeClasses({
                                        'bg-blue-100': row.id === highlightedRowId,
                                    })}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            ) : (
                <TableBody>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <TableRow key={`loader-${index}`}>
                            {table.getHeaderGroups()[0].headers.map((header) => (
                                <TableCell key={`loader-cell-${header.id}`}>
                                    <Skeleton className="h-3 w-2/3 md:h-4 md:w-full" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            )}
        </TableRoot>
    );
}
