import * as React from 'react';

import { ButtonProps, buttonVariants } from '../button';
import { ChevronLeftIcon, ChevronRightIcon } from '@in2event/icons';
import { twMergeClasses } from '../../lib';
import Link from 'next/link';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
    <nav
        role="navigation"
        aria-label="pagination"
        className={twMergeClasses('mx-auto flex w-full py-1', className)}
        {...props}
    />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
    ({ className, ...props }, ref) => (
        <ul
            ref={ref}
            className={twMergeClasses('flex flex-row items-center gap-base', className)}
            {...props}
        />
    ),
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(
    ({ className, ...props }, ref) => (
        <li ref={ref} className={twMergeClasses('', className)} {...props} />
    ),
);
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
    isActive?: boolean;
    disabled?: boolean;
    href: string; // The component a href can also be undefined but next/link requires a string
} & Pick<ButtonProps, 'size'> &
    Omit<React.ComponentProps<'a'>, 'href'>;

const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => {
    const variant: ButtonProps['variant'] = 'standard';

    return (
        <Link
            aria-current={isActive ? 'page' : undefined}
            className={twMergeClasses(
                buttonVariants({
                    variant,
                    size,
                }),
                className,
                {
                    'pointer-events-none opacity-60': props.disabled,
                },
            )}
            {...props}
        />
    );
};
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
    className,
    ...props
}: React.ComponentProps<typeof PaginationLink>) => (
    <PaginationLink aria-label="Go to previous page" size="md" className={className} {...props}>
        <ChevronLeftIcon className="size-5" />
        <span className="sr-only">Previous</span>
    </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
    <PaginationLink aria-label="Go to next page" size="md" className={className} {...props}>
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="size-5" />
    </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
    <span
        aria-hidden
        className={twMergeClasses('flex size-9 items-center justify-center', className)}
        {...props}
    >
        {'...'}
        <span className="sr-only">More pages</span>
    </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
};
